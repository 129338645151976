<template>
  <div class="plans-table">
    <div class="card-deck mb-3 text-center">
      <div class="card mb-4 box-shadow">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Gratuito</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">
            R$ 0
            <small class="text-muted">/mês</small>
          </h1>
          <ul class="">
            <li>Comentários e Partidas públicas</li>
            <li>15 partidas privadas por mês</li>
            <li>Busca Inteligente</li>
          </ul>
          <a href="#" class="btn btn-lg btn-block btn-outline-primary">Cadastre-se gratuitamente</a>
        </div>
      </div>
      <div class="card mb-4 box-shadow">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Premium</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">
            R$ 15,90
            <small class="text-muted">/mês</small>
          </h1>
          <ul class="">
            <li>Comentários em partidas privadas</li>
            <li>100 Partidas privadas por mês</li>
            <li>Busca Inteligente</li>
            <li>Collections/Favoritos</li>
            <li>Compartilhe partidas</li>
          </ul>
          <router-link
            :to="{ name: 'Upgrade' }"
            v-b-tooltip.hover
            title="você precisa fazer login"
            class="btn btn-lg btn-block btn-outline-primary"
          >Assine</router-link>
        </div>
      </div>
      <div class="card mb-4 box-shadow">
        <div class="card-header">
          <h4 class="my-0 font-weight-normal">Times</h4>
        </div>
        <div class="card-body">
          <h1 class="card-title pricing-card-title">
            R$ 39,90
            <small class="text-muted">/mês</small>
          </h1>
          <ul class="">
            <li>Compartilhamento e comentários privados</li>
            <li>300 partidas privadas por mês</li>
            <li>Acesso beta a novos recursos</li>
            <li>Pré-jogo / Lista de times</li>
            <li>Sessões de análise ao vivo com chat</li>
            <li>6 usuários inclusos</li>
          </ul>
          <router-link
            :to="{ name: 'Upgrade' }"
            v-b-tooltip.hover
            title="você precisa fazer login"
            class="btn btn-lg btn-block btn-outline-primary"
          >Assine</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlansTable",
  props: {
    currentPlan: String
  }
};
</script>

<style scoped lang="sass">
// $danger: #D9252A
.card-body
  height: 310px
  position: relative
  ul
    margin: 10px 0
    text-align: left
    padding: 0 20px
  .btn
    border-top: 1px solid #eee
    position: absolute
    bottom: 0
    left: 0
    width: 100%

.btn
  border-radius: 3px
  border: none
  font-weight: bold
  &.btn-primary
    background: $danger
  i.fab
    font-weight: normal
</style>
