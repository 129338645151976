<template>
  <div>
    <div class="home-featured">
      <div class="featured-container">
        <div class="container-fluid">
          <div class="row justify-content-around">
            <div class="col-12 col-md-4 left-side">
              <div class="content">
                <h1 v-html="$t('PAGE_LOGIN.main_title')"></h1>
                <h2>{{ $t("PAGE_LOGIN.main_subtitle") }}</h2>
              </div>

              <div class="actions-cta">
                <div>
                  <router-link class="btn btn-primary btn-signup" to="/signup">
                    {{ $t("PAGE_LOGIN.non_user_signup_now") }}
                  </router-link>
                </div>
                <div>
                  <router-link class="btn btn-light" to="/login">
                    {{ $t("PAGE_LOGIN.login_title") }}
                  </router-link>
                </div>
              </div>
            </div>

            <div class="col-12 col-md-6 right-side">
              <div class="video-container">
                <b-embed
                  type="video"
                  aspect="16by9"
                  autoplay
                  muted
                  loop
                  poster="/videos/demo-player.png"
                >
                  <source src="/videos/demo-player.webm" type="video/webm" />
                  <source src="/videos/demo-player.mp4" type="video/mp4" />
                </b-embed>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-12">
            <div class="integrations text-muted">
              <h5 class="section-title">
                {{ $t("PAGE_LOGIN.integrations_title") }}
              </h5>

              <div class="integrations-list">
                <b-img class="gc" src="/images/icon-gc.png" alt="Gamersclub" />
                <b-img class="esea" src="/images/icon-esea.png" alt="ESEA" />
                <b-img
                  class="valve"
                  src="/images/icon-valve.png"
                  alt="Matchmaking Valve"
                />
                <b-img
                  class="hltv"
                  src="/images/icon-hltv.png"
                  alt="HLTV.org"
                />
                <b-img
                  class="faceit"
                  src="/images/icon-faceit.png"
                  alt="FACEIT.com"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="overview-section">
      <div class="container-fluid">
        <div class="row justify-content-around">
          <div class="col-12 col-md-4">
            <div class="chrome-feature">
              <h4>
                <i class="fab fa-chrome"></i>
                {{ $t("PAGE_LOGIN.extension.title") }}
              </h4>
              <h5>
                {{ $t("PAGE_LOGIN.extension.subtitle") }}
              </h5>
              <p v-html="$t('PAGE_LOGIN.extension.line1')"></p>
              <p v-html="$t('PAGE_LOGIN.extension.line2')"></p>

              <router-link
                to="/integracoes/chrome"
                class="btn btn-secondary btn-lg"
                >{{ $t("PAGE_LOGIN.extension.see_more") }}</router-link
              >
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="chrome-container">
              <video
                controls
                autoplay="true"
                loop="true"
                style="width: 100%; height: auto"
              >
                <source
                  src="https://video.twimg.com/ext_tw_video/1158903706722734080/pu/vid/1124x720/xIz-qtGRCKxQW2TK.mp4?tag=10"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>

    <SignupBar></SignupBar>
  </div>
</template>

<script>
import PlansTable from "@/components/PlansTable";
import SignupBar from "@/components/SignupBar";
export default {
  name: "Home",
  metaInfo() {
    return {
      title: "Melhore no CS:GO",
    };
  },
  components: {
    PlansTable,
    SignupBar,
  },
  mounted() {
    if (this.$store.getters.isLoggedIn) {
      this.$router.push("/my_matches");
    }
  },
};
</script>

<style lang="sass" scoped>
.home-featured
  background: $primary url(/videos/login-background.png) no-repeat
  background-size: cover

  .featured-container
    padding: 4rem 0
    background: linear-gradient(254deg, rgba(0,0,0,.85) 0%, rgba(0,0,0,0.9) 65%, rgba(0,0,0,.95) 100%)
    -webkit-background-filter: blur(4px)
    background-filter: blur(4px)
    position: relative
    text-align: center
    color: white
    display: flex
    min-height: 80vh

    .left-side
      padding-top: 20px
      .content
        h1
          font-weight: 700
          text-align: left
          margin-top: 20px
          font-style: italic
          font-size: 6rem

        h2
          text-align: left
          font-weight: 500
          font-size: 2rem
          color: rgba(255, 255, 255, .8)
          font-family: $family-primary

      .actions-cta
        // display: flex
        // justify-items: center
        // align-items: center
        margin-top: 4rem
        > div
          text-align: left
          width: 100%
        .btn
          height: auto
          font-size: 2rem

        .btn-signup
          background: $danger
        .btn-light
          margin-top: 1rem
          background: transparent
          color: white
          border: none
          font-size: 1.3rem
          opacity: .8

    .right-side
      .video-container
        margin-top: 2rem
        display: flex
        // justify-items: center
        align-items: center

    .integrations
      padding-top: 40px
      padding-bottom: 20px
      margin-top: 4rem

      h5
        border-bottom: 0
        font-weight: 700
        letter-spacing: 1.5px
        font-size: 1.2rem
        color: rgba(255, 255, 255, 0.5)
        margin-bottom: .5rem

      .integrations-list
        margin: 10px 0 20px
        img
          width: auto
          opacity: .6
          margin: 0 25px
          transition: all .2s
          &:hover
            opacity: 1
          &.gc
            height: 60px
          &.esea
            display: none
            height: 23px
          &.valve
            height: 34px
          &.hltv
            height: 70px
          &.faceit
            height: 36px

.features-section
  padding-top: 1rem
  padding-bottom: 2rem
  .title
    font-weight: 700
    color: $danger

  h5.section-title
    margin-top: 10px
    margin-bottom: 40px

  h4
    margin: 20px 0 0
  .card-text
    font-size: 85%

@mixin align-horizontal
  position: absolute
  left: 50%
  transform: translateX(-50%)
.overview-section
  padding: 8rem 0
  background: darken(#0e1327, 8%)
  color: white
  overflow: hidden

  .chrome-feature
    i
      font-size: 20px
      padding: 20px 0
      margin: 0
      border-bottom: 1px solid rgba(255, 255, 255, .6)
    h5
      font-weight: 500
      font-size: 2rem

    p
      font-size: 1.3rem
      line-height: 150%
      margin: 2rem 0 0rem
      &:not(:first-of-type)
        margin: 1rem 0 2rem
  .chrome-feature
    border-bottom: none

  .chrome-container
    margin: 2rem 0 0px 0
    border: 20px solid rgba(255, 255, 255, .1)
    border-radius: 10px
    box-shadow: 0px 2em 7rem rgba(255,255,255,0.075)

.last-cta
  padding-top: 4rem
  padding-bottom: 2rem
  h1
    font-size: 28px
    font-weight: bold
</style>
