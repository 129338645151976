<template>
  <div class="signup-cta-bar">
    <div class="container">
      <h1>Você tá esperando o que?</h1>
      <p>Seu próximo passo pra aprender mais é</p>

      <router-link class="btn btn-lg btn-primary" to="/signup">
        {{ $t("PAGE_LOGIN.non_user_signup_now") }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignupBar",
};
</script>

<style scoped lang="sass">
.signup-cta-bar
  background: $danger url('/fightback-logo-white.png') no-repeat
  color: white
  overflow: hidden
  padding: 2rem 0
  background-position: center right

  @media screen and (max-width: 600px)
    // color: black
    font-size: 1rem
    background-size: 120px
    background-position: bottom right
</style>
